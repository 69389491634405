import { createTheme, ThemeOptions } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#4b4b4b',
    },
    secondary: {
      main: '#dac284',
    },
  },
};
export const theme = createTheme({
  palette: {
    primary: {
      main: '#4b4b4b',
    },
    secondary: {
      main: '#dac284',
    },
  },
});
